<template>
  <div>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row
        align-h="center"
        class="my-2"
      >
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
            />
          </b-input-group>
        </b-col>
        <b-col
          v-if="isOwner"
          cols="3"
        >
          <master-select-input 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="agentList"
        :fields="fields"
        :busy="isFetching"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link :to="`/agents/detail/${data.item.id}`">
            {{ `${data.value || ''} ${data.item.lastname || ''}` }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="$displayStatusColor(data.value)">
            {{
              $te(`utils.status.${data.value}`)
                ? $t(`utils.status.${data.value}`)
                : data.value
            }}
          </b-badge>
        </template>
        <template #cell(isEnableLineAuth)="data">
          <div>
            <b-badge
              v-if="data.value"
              variant="success"
            >
              enabled
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              disabled
            </b-badge>
          </div>
        </template>
        <template #cell(lineId)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(id)="data">
          <b-dropdown
            variant="info"
            size="sm"
            dropleft
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="SettingsIcon"
                size="20"
              />
            </template>
            <b-dropdown-item 
              v-if="$allowPermission('master:manage.agent')"
              :to="`/agents/detail/${data.item.id}`"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-1"
              />
              {{ $t('buttons.edit') }}
            </b-dropdown-item>
            <b-dropdown-item
              :to="`/bank-accounts/create?agentId=${data.item.id}`"
            >
              <feather-icon
                icon="BookOpenIcon"
                class="mr-1"
              />
              {{ $t('buttons.add_bank_account') }}
            </b-dropdown-item>
            <b-dropdown-item @click="toggleDirectDepositModal(data.value)">
              <feather-icon
                icon="PlusCircleIcon"
                class="text-success mr-1"
              />
              <span class="text-success">
                {{ $t('credits.direct_deposit') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleDirectWithdrawModal(data.value)">
              <feather-icon
                icon="MinusCircleIcon"
                class="text-warning mr-1"
              />
              <span class="text-warning">
                {{ $t('credits.direct_withdraw') }}
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              :href="`/credits/agent?agentId=${data.item.id}`"
              disabled
            >
              <feather-icon
                icon="FileTextIcon"
                class="mr-1"
              />
              {{ $t('credits.credit_transaction') }}
            </b-dropdown-item>
            <b-dropdown-item @click="onLogin(data.value)">
              <feather-icon
                icon="LogOutIcon"
                class="mr-1"
              />
              {{ $t('buttons.access_login') }}
            </b-dropdown-item>
            <b-dropdown-item @click="onDelete(data.value)">
              <span class="text-danger">
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-1"
                />
                {{ $t('buttons.remove') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{
              $t('messages.nothing_here')
            }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row>
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
          />
        </b-col>
      </b-row>
    </b-card>
    <DirectDepositModal
      ref="directDepositTransfer"
      :agent-id="selectedAgentId"
      no-btn
    />
    <DirectWithdrawModal
      ref="directWithdrawTransfer"
      :agent-id="selectedAgentId"
      no-btn
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Agent List',
  },
  components: {
    DirectDepositModal: () => import('../common-forms/DirectDepositAgentModal.vue'),
    DirectWithdrawModal: () => import('../common-forms/DirectWithdrawAgentModal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      search: '',
      onSearchTimeout: null,
      selectedMaster: '',
      selectedAgentId: '',
      selectedNote: '',
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '180px',
          },
        },
        {
          key: 'prefix',
          label: 'prefix',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'registrationUrl',
          label: 'ลิ้งค์สมัคร',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'lineId',
          label: 'Line @ID',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'isEnableLineAuth',
          label: this.$t('user.has_line_signin'),
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'id',
          label: '-',
          class: 'text-right',
        },
      ],
      selectedAgent: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.agent.isFetchingAgents,
    }),
    ...mapGetters(['isOwner', 'agents']),
    agentList() {
      return this.agents?.items || []
    },
    pagination() {
      return this.agents.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
       if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
    if (this.isOwner) {
      this.addFieldForOwner('agentType')
    }
  },
  methods: {
    ...mapActions(['fetchAgents', 'loginAsAgent', 'deleteAgent']),
    fetchData() {
      this.fetchAgents({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    addFieldForOwner(key) {
      const found = this.fields.find((field) => field.key === key)
      if (!found) {
        this.fields.splice(1, 0, {
          key,
          label: this.$t('roles.title'),
        })
      }
    },
    toggleDirectDepositModal(id) {
      this.$refs.directDepositTransfer.toggleModal()
      this.selectedAgentId = id
    },
    toggleDirectWithdrawModal(id) {
      this.$refs.directWithdrawTransfer.toggleModal()
      this.selectedAgentId = id
    },
    onLogin(id){
      this.loginAsAgent(id);
    },
    onDelete(agentId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteAgent(agentId)
          }
        })
    },
  },
}
</script>
